import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { auditApi } from "../../../components/Variables"
import Layout from "../../../components/Layout/Layout"
import Audit from "../../../components/Audit/Audit"
import Loading from "../../../components/Loading/Loading"
import footer_bg from "../../../assets/img/old/tlo-konsultacje-03-3.webp"
import { freeReportView } from "../../../components/Statuses/Statuses"

import "../../../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const Audyt = props => {
  const id = props.params.id
  const [data, setData] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage("de")
    freeReportView(id)
  }, [id])

  useEffect(() => {
    fetch(auditApi + "/getSimpleAuditById/" + id + "/de")
      .then(res => {
        if (!res.ok) {
          console.log(res)
          throw new Error("Błędna odpowiedź serwera.")
        } else {
          return res.json()
        }
      })
      .then(res => {
        setData(res)
      })
      .catch(err => {
        console.log(err)
      })
  }, [id])

  return (
    <Layout
      seo={{
        title: "Sehen Sie das SEO-Auditergebnis für Ihre Webseite • WeNet.pl",
        description:
          "Prüfen Sie, ob Ihre Webseite die Anforderungen von Google erfüllt • Sehen Sie die Ergebnisse Ihres SEO-Audits und finden Sie heraus, was Sie verbessern können",
        hreflangs: {
          pl: "audyt/" + id + "/",
          en: "en/audit/" + id + "/",
          de: "de/audit/" + id + "/",
        },
        robots: "noindex, nofollow",
      }}
    >
      {data ? (
        <>
          <Audit data={data} id={id} />
          <section
            className="outro"
            style={{
              backgroundImage: `url(${footer_bg})`,
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3>
                    Nutzen Sie unser SEO-Angebot und bestellen Sie Optimierungsspezialisten der{" "}
                    <span style={{ display: "inline-block" }}>WeNet</span> - stärken Sie Ihre Seitenposition!
                  </h3>

                  <Link to={t("link-url-contact")} className="btn btn-primary">
                    Ich möchte ein kostenloses Optimierungsangebot
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <Loading />
      )}
    </Layout>
  )
}

export default Audyt
